@import "../../variables";

.pgn__card-wrapper-image-cap {
  max-height: 200px !important;
  img {
    object-fit: cover;
    max-height: inherit;
    width: 100%;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
}
.top-btn-container {
  z-index: 99;
  right: 10px;
  position: absolute;
  .cost-tag-btn {
    font-size: 12px;
    border-radius: 20px;
    color: #ffffff;
    margin-top: 10px;
    border: 1px solid #ffffff;
    font-weight: 700;
  }
}
div.card {
  box-shadow: 0 2px 12px 0 rgba(18, 18, 18, 0.12);
}

div.card-body {
  padding: 30px 49px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 12px 0 rgba(18, 18, 18, 0.12);
  @media screen and (max-width: 500px) {
    padding: 20px;
  }
  .pgn__card-header {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: #454545;
  }
  .pgn__card-section {
    margin-bottom: 30px;
    h3 {
      font-size: 23px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.09;
      letter-spacing: normal;
      color: #121212;
    }
  }
}

.pgn__card-header .pgn__card-header-content {
  margin-top: 0 !important;
}

.pgn__card-footer {
 
  button {
    border-radius: 25px;
    background-color: #ffffff;
    color: #121212;
    font-size: 14px;
    border: solid 2px #121212;
  }
}

.course-card {
  margin-bottom: 30px;
}

div.center {
  display: flex;
  justify-content: center;
  align-items: center;
  button.load-more.btn-primary:not(.disabled) {
    @extend .theme-btn;
  }
}

a.view-more {
  border-radius: 4px;
  color: #000;
  border-color: #25a78e;

  &:hover:not(.disabled),
  &:focus:not(.disabled),
  &:active(.disabled) {
    background-color: #1b5c4f;
    border-color: #1b5c4f;
    border-radius: 4px;
    color: #fff;
  }
}

a.explore {
  background-color: #1b5c4f !important;
  border-color: #1b5c4f !important;
  border-radius: 4px;
  color: #fff !important;
  text-decoration: none !important;

  &:hover:not(.disabled),
  &:focus:not(.disabled),
  &:active(.disabled) {
    background-color: #1b5c4f;
    border-color: #1b5c4f;
    border-radius: 4px;
    color: #fff;
  }
}

.gray-continer {
  margin-top: 105px;
  padding: 124px;
  text-align: justify;
  background-color: #ececec;
  @media screen and (max-width: 767px) {
    padding: 20px;
    text-align: left;
  }
  h2 {
    font-size: 40px;
    margin-bottom: 30px;
    font-weight: 600;
    line-height: 1.18;
    color: #121212;
  }
  p {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.56;
    color: #454545;
  }
}

a.card-link:hover * {
  cursor: pointer;
  text-decoration: none;
}

h2.course-title {
  margin-bottom: 30px;
  text-align: left;
  font-size: 40px;
}

div.cards-continer,
div.wrapper-continer {
  max-width: calc(1280px - 260px);
  margin: 0 auto;
}
.gray-continer-claim {
  background-color: #ececec;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  .wrapper-continer {
    min-height: 50vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    padding: 150px;

    @media (max-width: 767px) {
     padding: 0;
    }
    button {
      @extend .theme-btn;
    }
  }
}

.explore-btn {
  border-radius: 4px;
  background-color: $primary !important;
  color: $black !important;
  border-color: $primary !important;
  padding: 15px !important;
  &:hover {
    background-color: $darkGreen !important;
    color: $white !important;
    border: 1px solid $darkGreen !important;
  }
}

.white-continer-claim {
  background-color: #fff;
  .wrapper {
    min-height: 30vh;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
}

img.course-image {
  width: 100%;
}

.course-info-banner {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

button.enroll-button {
  @extend .theme-btn;
  margin-top: 0;
}

.tabs {
  display: flex;
  justify-content: center;
}

.tabitem {
  border: 2px solid #fff;
  cursor: pointer;
  width: 100px;
  height: 100px;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tabitem--inactive {
  opacity: 0.3;
}

.tabitem__icon {
  font-size: 22px;
}

.tabitem__title {
  color: #121212;
  font-size: 16px;
  margin: 10px 0;
}

.content {
  text-align: center;
  margin-top: 15px;
  font-size: 22px;
}

.tabs-container {
  display: flex;
  justify-content: space-between;
}

.about-course-tabs {
  text-align: left;
}

.section-1 {
  background-image: url("https://images.unsplash.com/photo-1513077202514-c511b41bd4c7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80");
  background-size: cover;
  background-repeat: no-repeat;
}

.section-1-text {
  font-size: 3.5rem;
  text-align: center;
}

.section-1-description {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.pgn__card-footer{
  justify-content: flex-start !important;
}

.section-2 {
  text-align: center;
}

.load-more {
  @extend .theme-btn;
}

.free {
  width: 59px;
  height: 30px;
  border-radius: 15px !important;
  border: solid 2px #fff !important;
  background-color: #121212 !important;
}

.navigation {
  display: flex;
  justify-content: space-between;
  background-color: transparent;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
   }
 }

 .nav-auth-container {
  padding: 10px;
  margin-right: 10px;
  column-gap: 10px;

  .navigation-link {
    margin-right: 10px;
  }

  button, .username, .username-icon {
    color: rgb(0, 0, 0);
  }

  .username {
    // margin-top: 20px;
  }

  .btn-icon__icon-container {
    color: #928e8e;
  }

  .btn-icon .btn-icon__icon {
    height: 1.5em;
    width: 1.5em;
  }

 }

 .btn-login {
  background-color: $primary !important;
  color: black !important;

  &:hover{
  color: white !important;
  background-color: $darkGreen !important;
  }
 }

 .btn-register {
background-color: $primary !important;
color: black !important;
  &:hover{
  color: white !important;
  background-color: $darkGreen !important;
  }
 }

 .home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-logo img {
  padding-left: 15px;
  max-height: 50px; /* Adjust as needed */
  width: auto; /* Maintains aspect ratio */
}

div.nav-auth-container {
  text-align: right;
}