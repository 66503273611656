$primary: #25a78e;
$darkGreen: #1b5c4f;
$white: #fff;
$black: #000;

.theme-btn {
  border-radius: 4px !important;
  background-color: $primary !important;
  color: $black !important;
  border-color: $primary !important;
  padding: 15px !important;
  &:hover {
    background-color: $darkGreen !important;
    color: $white !important;
    border: 1px solid $darkGreen !important;
  }
}

a.view-more {
  border-radius: 4px;
  color: #000;
  border-color: #25a78e;

  &:hover:not(.disabled),
  &:focus:not(.disabled),
  &:active(.disabled) {
    background-color: #1b5c4f;
    border-color: #1b5c4f;
    border-radius: 4px;
    color: #fff;
  }
}

a.explore {
  color: #000;
  border-color: #25a78e;
  text-decoration: none;

  &:hover:not(.disabled),
  &:focus:not(.disabled),
  &:active(.disabled) {
    background-color: #1b5c4f;
    border-color: #1b5c4f;
    border-radius: 4px;
    color: #fff;
  }
}